body {
  margin: 0;
  padding-bottom: 120px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-navigation {
  text-align: center;
  padding: 4px;
}
.top-navigation img {
  height: 100px;
}
.blue-bar {
  display: flex;
  background-color: rgb(1, 158, 221);
  height: 40px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
}
.blue-bar p {
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
}
.blue-bar a {
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px;
  height: 80px;
  background-color: rgb(34, 34, 34);
  color: white;
  text-align: center;
  font-size: 13px;
}

.franz {
  color: #019edd;
  /*font-size: 0.8em;*/
}

.footer-buttons {
  margin-top: 40px;
  margin-bottom: 20px;
}